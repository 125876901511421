<template>
  <div class="AddNewSupplierTwo">
    <div class="discount-preset">
      <NeoTitle :text="$t('customerConfig.discount_preset')" class="_mb-21" />
      <DiscountPresetTable :datap="info.discountList" :info="info" :edit="info.status !== 1" />
    </div>
    <PriceListMod v-if="show" ref="PriceListMod" :info="info" />
    <footer class="_mt-30">
      <a-button
        v-if="!info.status && !info.inactive"
        @click="sendEmail"
        :loading="loading"
        class="_w-120"
        type="primary"
        >{{ $t('customerConfig.send_ok_email') }}</a-button
      >
    </footer>
  </div>
</template>

<script>
import DiscountPresetTable from '@/components/DiscountPresetTable'
import PriceListMod from '@/components/PriceListMod'

export default {
  components: {
    DiscountPresetTable,
    PriceListMod,
  },
  props: {
    show: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      dataSourceOne: [],
      tableColumn: [],
      discountList: [],
      addPriceVisible: false,
      agencyInfo: {
        id: null,
        currency: null,
      },
      priceFileList: [],
      info: { discountList: [] },
      loading: false,
      reFresh: true,
      supDiscountInfo: [],
    }
  },
  computed: {
    id() {
      return this.$route.query.id
    },
  },
  mounted() {
    this.$bus.$off().$on('SupplierBaseInfoDetail', (data) => {
      this.info = data
    })
  },
  methods: {
    async sendEmail() {
      try {
        this.loading = true
        const { id, supTenantCode } = this.info
        const params = { id, supTenantCode }
        // eslint-disable-next-line no-unused-vars
        const data = await this.$http('sendConfirmEmail', params)
        this.loading = false
        if (data) {
          this.$message.success(this.$t('customerConfig.send_email_succ'))
          setTimeout(() => {
            this.$router.push({ path: `${this.$g.route.coConfig_home}?tab=2` })
          }, 200)
        } else {
          this.$message.error(this.$t('customerConfig.send_email_fail'))
        }
      } catch (err) {
        this.loading = false
        this.$httpNotify(err)
      }
    },
  },
}
</script>

<style lang="less" scoped>
.AddNewSupplierTwo {
  ::v-deep {
    .ant-descriptions-bordered .ant-descriptions-item-label {
      background-color: @tbl-header-bgc;
    }
  }
}
</style>
